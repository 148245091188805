import React, { useEffect, useState } from "react";
import logoAC from "../../assets/Logo Assesment Center-06.png";
import imgHeader from "../../assets/BPKH-01-01.png";
import imgBanner from "../../assets/BPKH revisi-02.jpg";
import imgMobile from "../../assets/7607115_3670299.svg";
import closedImg from "../../assets/closed.jpg";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";
import { Modal, Spinner } from "react-bootstrap";
import ReactSelect from "react-select";
import { useParams } from "react-router-dom";

const Form = () => {
  const [show, setShow] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [ktp, setKtp] = useState(null);
  const [ijazah, setIjazah] = useState([{}]);
  const [certificate, setCertificate] = useState([{}]);
  const [ijazahFilled, setIjazahFilled] = useState(false);
  const [certificateFilled, setCertificateFilled] = useState(false);
  const [cv, setCv] = useState(null);
  const [kelulusan, setKelulusan] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [skck, setSkck] = useState(null);
  const [lamaran, setLamaran] = useState(null);
  const [pakta, setPakta] = useState(null);
  const [isExperienceYear, setIsExperienceYear] = useState("tidak");
  const [isTOEFL, setIsTOEFL] = useState("tidak");
  const [isTOEIC, setIsTOEIC] = useState("tidak");
  const [isIELTS, setIsIELTS] = useState("tidak");
  const [dateBirth, setDateBirth] = useState("");
  const [university, setUniversity] = useState("");
  const [faculty, setFaculty] = useState("");
  const [universityS2, setUniversityS2] = useState("");
  const [facultyS2, setFacultyS2] = useState("");
  const [gender, setGender] = useState("");
  const [dataFaculty, setDataFaculty] = useState([]);
  const [dataUniversity, setDataUniversity] = useState([]);
  const [dataFacultyS2, setDataFacultyS2] = useState([]);
  const [dataUniversityS2, setDataUniversityS2] = useState([]);
  const [dataPosition, setDataPosition] = useState([]);
  const [dataCompany, setDataCompany] = useState([]);
  const [errorNik, setErrorNik] = useState("");
  const [errorGraduate, setErrorGraduate] = useState("");
  const [errorIpk, setErrorIpk] = useState("");
  const [errorGraduateS2, setErrorGraduateS2] = useState("");
  const [errorIpkS2, setErrorIpkS2] = useState("");
  const [errorAddress, setErrorAddress] = useState("");
  const [form, setForm] = useState({
    name: "",
    place_birth: "",
    nik: "",
    address: "",
    phone: "",
    email: "",
    experience_year: "",
    university_str_s1: "",
    faculty_str_s1: "",
    ipk_s1: "",
    graduation_year_s1: "",
    university_str_s2: "",
    faculty_str_s2: "",
    ipk_s2: "",
    graduation_year_s2: "",
    toefl: "",
    toeic: "",
    ielts: "",
  });
  const { id } = useParams();
  const { id2 } = useParams();

  const getListUniversity = (searchIndex) => {
    axios
      .get(`${process.env.REACT_APP_URL}ac/recruitment/list_university/10/1`, {
        headers: {
          Authorization:
            "Basic " +
            btoa(
              `${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`
            ),
        },
        params: { search: searchIndex },
      })
      .then((res) => {
        const data = res?.data?.data?.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setDataUniversity(data);
      });
  };

  const getListUniversityS2 = (searchIndex) => {
    axios
      .get(`${process.env.REACT_APP_URL}ac/recruitment/list_university/10/1`, {
        headers: {
          Authorization:
            "Basic " +
            btoa(
              `${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`
            ),
        },
        params: { search: searchIndex },
      })
      .then((res) => {
        const data = res?.data?.data?.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setDataUniversityS2(data);
      });
  };

  const getListFaculty = (searchIndex) => {
    axios
      .get(`${process.env.REACT_APP_URL}ac/recruitment/list_faculty/10/1`, {
        headers: {
          Authorization:
            "Basic " +
            btoa(
              `${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`
            ),
        },
        params: { search: searchIndex },
      })
      .then((res) => {
        const data = res.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setDataFaculty(data);
        setDataFacultyS2(data);
      });
  };

  const getListFacultyS2 = (searchIndex) => {
    axios
      .get(`${process.env.REACT_APP_URL}ac/recruitment/list_faculty/10/1`, {
        headers: {
          Authorization:
            "Basic " +
            btoa(
              `${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`
            ),
        },
        params: { search: searchIndex },
      })
      .then((res) => {
        const data = res.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setDataFaculty(data);
        setDataFacultyS2(data);
      });
  };

  // useEffect(() => {
  //   getListUniversity();
  //   getListFaculty();
  //   getListUniversityS2();
  //   getListFacultyS2();
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_URL}ac/recruitment/participant/company_detail/${id2}`,
  //       {
  //         headers: {
  //           Authorization:
  //             "Basic " +
  //             btoa(
  //               `${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`
  //             ),
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       setDataCompany(res.data);
  //       // setLoading(false);
  //     });
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_URL}ac/recruitment/participant/position/detail/${id}`,
  //       {
  //         headers: {
  //           Authorization:
  //             "Basic " +
  //             btoa(
  //               `${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`
  //             ),
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       setDataPosition(res.data.data);
  //       setLoading(false);
  //     });
  // }, []);

  const addCertificate = () => {
    setCertificate([...certificate, {}]);
  };

  const addIjazah = () => {
    setIjazah([...ijazah, {}]);
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleDisabled = () => {
    if (
      form.name === "" ||
      form.place_birth === "" ||
      form.nik.length < 16 ||
      form.address.length < 10 ||
      form.ipk_s1 > 4 ||
      form.ipk_s1 === "" ||
      form.phone === "" ||
      form.email === "" ||
      form.graduation_year_s1.length < 4 ||
      ktp === null ||
      cv === null ||
      ijazahFilled === false ||
      certificateFilled === false ||
      kelulusan === null ||
      photo === null ||
      skck === null ||
      lamaran === null ||
      pakta === null ||
      faculty.value === "" ||
      university.value === "" ||
      dateBirth === "" ||
      gender === "" ||
      (faculty.label === "Lainnya" && form.faculty_str_s1 === "") ||
      (university.label === "Lainnya" && form.university_str_s1 === "")
    ) {
      return true;
    }
  };

  const handleSubmit = () => {
    setLoadingButton(true);
    const PPData = new FormData();
    PPData.append("ktp", ktp);
    for (let i = 0; i < certificate.length; i++) {
      PPData.append("certificate", certificate[i]);
    }
    for (let i = 0; i < ijazah.length; i++) {
      PPData.append("ijazah", ijazah[i]);
    }
    PPData.append("cv", cv);
    PPData.append("kelulusan", kelulusan);
    PPData.append("photo", photo);
    PPData.append("skck", skck);
    PPData.append("lamaran", lamaran);
    PPData.append("pakta", pakta);
    PPData.append("date_birth", dateBirth);
    PPData.append("name", form.name);
    PPData.append("place_birth", form.place_birth);
    PPData.append("nik", form.nik);
    PPData.append("gender", gender);
    PPData.append("address", form.address);
    PPData.append("phone", form.phone);
    PPData.append("email", form.email);
    PPData.append("id_university_s1", university.value);
    PPData.append(
      "id_university_s2",
      universityS2 === "" ? "" : universityS2.value
    );
    PPData.append("id_faculty_s1", faculty.value);
    PPData.append("id_faculty_s2", facultyS2 === "" ? "" : facultyS2.value);
    PPData.append("university_str_s1", form.university_str_s1);
    PPData.append("faculty_str_s1", form.faculty_str_s1);
    PPData.append("ipk_s1", Number(form.ipk_s1.replace(/,/g, ".")));
    PPData.append("graduation_year_s1", form.graduation_year_s1);
    PPData.append("university_str_s2", form.university_str_s2);
    PPData.append("faculty_str_s2", form.faculty_str_s2);
    PPData.append("ipk_s2", Number(form.ipk_s2.replace(/,/g, ".")));
    PPData.append("graduation_year_s2", form.graduation_year_s2);
    PPData.append(
      "experience_year",
      form.experience_year === "" ? 0 : form.experience_year
    );
    PPData.append("toefl", form.toefl);
    PPData.append("toeic", form.toeic);
    PPData.append("ielts", form.ielts);
    PPData.append("id_position", id);
    axios
      .post(`${process.env.REACT_APP_URL}ac/recruitment/save`, PPData, {
        headers: {
          Authorization:
            "Basic " +
            btoa(
              `${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`
            ),
        },
      })
      .then((res) => {
        setShow(false);
        setShowSuccess(true);
        setInterval(() => {
          window.location.reload(false);
        }, 5000);
      })
      .catch((err) => {
        setLoadingButton(false);
        alert("Failed to upload.");
      });
  };

  return (
    <div>
      {/* <div className="d-flex justify-content-between shadow bg-white">
        <div className="d-flex py-4 ps-4 d-block d-sm-none mx-auto">
          <img alt="" src={logoAC} width="auto" height={40} />
          <div className="mx-3 fw-bold vr"></div>
          <img alt="" src={dataCompany.image} width="auto" height={40} />
        </div>
        <div className="d-flex py-4 ps-4 d-none d-sm-block">
          <img alt="" src={logoAC} width="auto" height={40} />
          <div className="mx-3 fw-bold vr"></div>
          <img alt="" src={dataCompany.image} width="auto" height={40} />
        </div>
        <p className="fs-3 fw-bold mb-0 text-center text-uppercase py-4 d-none d-sm-block">
          Form Aplikasi
        </p>
        <img
          className="d-none d-sm-block"
          alt=""
          src={imgHeader}
          width={220}
          height="auto"
        />
      </div> */}
      {/* {loading === false ? (
        <div className="text-center p-5">
          <Spinner animation="border" />
        </div>
      ) : ( */}
        <div className="card border-0">
          <img
            className="mx-auto m-3"
            style={{ width: "35%" }}
            alt=""
            src={closedImg}
          />
          {/* <img className="d-block d-sm-none" alt="" src={imgMobile} />
          <div className="d-block d-sm-none card mt-3 mx-3 p-3 bg-blue border-0">
            <p className="text-center mb-0 fw-bold text-white">
              Peserta diharapkan untuk akses via web-browser (best view) laptop
            </p>
          </div>
          <div className="row m-5 d-none d-sm-flex">
            <div className="row gap-3 ps-4 pe-0">
              <div
                className="col card border-0 shadow p-0 py-4 text-white text-wrap pe-2"
                style={{ backgroundColor: "rgba(100, 153, 233, 0.8)" }}
              >
                <p className="fw-bold fs-5 mb-2 ps-4">
                  {dataPosition.position}
                </p>
                <p className="fw-bold mb-1 ps-4">Persyaratan Khusus:</p>
                {dataPosition?.requirements?.map((item) => (
                  <ul className="mb-1 ps-5">
                    <li>{item.name}</li>
                  </ul>
                ))}
              </div>
              <img
                alt=""
                src={imgBanner}
                width="auto"
                height="auto"
                className="col-4 rounded shadow p-0"
              />
            </div>
            <p className="fw-bold mt-5">Data Diri</p>
            <div className="col">
              <div className="form-group card border-0 shadow w-100">
                <label className="bg-blue p-input text-white rounded-top">
                  Nama
                  <span className="text-danger">
                    <span className="text-danger fs-5 fw-bold">*</span>
                  </span>
                  :
                </label>
                <div className="bg-blue2 p-input rounded-bottom">
                  <input
                    type="text"
                    name="name"
                    className="form-control mt-1"
                    placeholder="nama"
                    value={form.name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group card border-0 shadow w-100 mt-3">
                <label className="bg-blue p-input text-white rounded-top">
                  Tempat Tanggal Lahir
                  <span className="text-danger fs-5 fw-bold">*</span>:
                </label>
                <div className="bg-blue2 p-input rounded-bottom">
                  <input
                    type="text"
                    name="place_birth"
                    className="form-control me-2 h-100 mt-2"
                    placeholder="tempat"
                    value={form.place_birth}
                    onChange={handleChange}
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="tanggal lahir"
                      onChange={(dateString) =>
                        setDateBirth(
                          Math.floor(new Date(dateString).getTime() / 1000)
                        )
                      }
                      className="date-picker mt-2 rounded bg-white border-0 w-100"
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div className="form-group card border-0 shadow w-100 mt-3">
                <label className="bg-blue p-input text-white rounded-top">
                  NIK<span className="text-danger fs-5 fw-bold">*</span>:
                </label>
                <div className="bg-blue2 p-input rounded-bottom">
                  <input
                    type="number"
                    name="nik"
                    className="form-control mt-1"
                    placeholder="nik"
                    value={form.nik}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        [e.target.name]: e.target.value,
                      });
                      if (e.target.value.length < 16) {
                        setErrorNik("Isi dengan NIK yang valid");
                      } else {
                        setErrorNik("");
                      }
                    }}
                    onKeyPress={(e) => {
                      if (e.target.value.length >= 16) e.preventDefault();
                    }}
                  />
                  <p className="text-danger mb-0">{errorNik}</p>
                </div>
              </div>
              <div className="form-group card border-0 shadow w-100 mt-3">
                <label className="bg-blue p-input text-white rounded-top">
                  Gender<span className="text-danger fs-5 fw-bold">*</span>:
                </label>
                <div className="bg-blue2 p-input rounded-bottom">
                  <select
                    type="text"
                    name="gender"
                    className="form-select mt-1"
                    placeholder="gender"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <option value="">Pilih...</option>
                    <option value="P">Perempuan</option>
                    <option value="L">Laki-Laki</option>
                  </select>
                </div>
              </div>
              <div className="form-group card border-0 shadow w-100 mt-3">
                <label className="bg-blue p-input text-white rounded-top">
                  Alamat<span className="text-danger fs-5 fw-bold">*</span>:
                </label>
                <div className="bg-blue2 p-input rounded-bottom">
                  <textarea
                    type="text"
                    name="address"
                    className="form-control mt-1"
                    placeholder="alamat"
                    value={form.address}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        [e.target.name]: e.target.value,
                      });
                      if (e.target.value.length < 10) {
                        setErrorAddress("Isi dengan alamat yang valid");
                      } else {
                        setErrorAddress("");
                      }
                    }}
                  />
                  <p className="text-danger mb-0">{errorAddress}</p>
                </div>
              </div>
              <div className="form-group card border-0 shadow w-100 mt-3">
                <label className="bg-blue p-input text-white rounded-top">
                  Nomor Telepon
                  <span className="text-danger fs-5 fw-bold">*</span>:
                </label>
                <div className="bg-blue2 p-input rounded-bottom">
                  <input
                    type="text"
                    name="phone"
                    className="form-control mt-1"
                    placeholder="nomor telepon"
                    value={form.phone}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="form-group card border-0 shadow w-100 mt-3">
                <label className="bg-blue p-input text-white rounded-top">
                  Email<span className="text-danger fs-5 fw-bold">*</span>:
                </label>
                <div className="bg-blue2 p-input rounded-bottom">
                  <input
                    type="text"
                    name="email"
                    className="form-control mt-1"
                    placeholder="email"
                    value={form.email}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="form-group card border-0 shadow w-100">
                <label className="bg-blue p-input text-white rounded-top">
                  Pengalaman Kerja (tahun):
                </label>
                <div className="bg-blue2 p-input rounded-bottom">
                  <p className="mb-1">Apakah Anda memiliki pengalaman kerja?</p>
                  <select
                    className="form-select"
                    onChange={(e) => setIsExperienceYear(e.target.value)}
                  >
                    <option value="tidak">Tidak</option>
                    <option value="ya">Ya</option>
                  </select>
                  {isExperienceYear === "ya" ? (
                    <input
                      type="number"
                      min="1"
                      name="experience_year"
                      className="form-control mt-1"
                      placeholder="pengalaman kerja (tahun)"
                      value={form.experience_year}
                      onChange={handleChange}
                      onWheel={(e) => e.target.blur()}
                      onKeyPress={(e) => {
                        if (e.target.value.length >= 2) e.preventDefault();
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="form-group card border-0 shadow w-100 mt-3">
                <label className="bg-blue p-input text-white rounded-top">
                  Skor TOEIC:
                </label>
                <div className="bg-blue2 p-input rounded-bottom">
                  <p className="mb-1">Apakah Anda memiliki skor TOEIC?</p>
                  <select
                    className="form-select"
                    onChange={(e) => setIsTOEIC(e.target.value)}
                  >
                    <option value="tidak">Tidak</option>
                    <option value="ya">Ya</option>
                  </select>
                  {isTOEIC === "ya" ? (
                    <input
                      type="number"
                      min="1"
                      name="toeic"
                      className="form-control mt-1"
                      placeholder="skor TOEIC"
                      value={form.toeic}
                      onChange={handleChange}
                      onWheel={(e) => e.target.blur()}
                      onKeyPress={(e) => {
                        if (e.target.value.length >= 3) e.preventDefault();
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="form-group card border-0 shadow w-100 mt-3">
                <label className="bg-blue p-input text-white rounded-top">
                  Skor TOEFL:
                </label>
                <div className="bg-blue2 p-input rounded-bottom">
                  <p className="mb-1">Apakah Anda memiliki skor TOEFL?</p>
                  <select
                    className="form-select"
                    onChange={(e) => setIsTOEFL(e.target.value)}
                  >
                    <option value="tidak">Tidak</option>
                    <option value="ya">Ya</option>
                  </select>
                  {isTOEFL === "ya" ? (
                    <input
                      type="number"
                      min="1"
                      name="toefl"
                      className="form-control mt-1"
                      placeholder="skor TOEFL"
                      value={form.toefl}
                      onChange={handleChange}
                      onWheel={(e) => e.target.blur()}
                      onKeyPress={(e) => {
                        if (e.target.value.length >= 3) e.preventDefault();
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="form-group card border-0 shadow w-100 mt-3">
                <label className="bg-blue p-input text-white rounded-top">
                  Skor IELTS:
                </label>
                <div className="bg-blue2 p-input rounded-bottom">
                  <p className="mb-1">Apakah Anda memiliki skor IELTS?</p>
                  <select
                    className="form-select"
                    onChange={(e) => setIsIELTS(e.target.value)}
                  >
                    <option value="tidak">Tidak</option>
                    <option value="ya">Ya</option>
                  </select>
                  {isIELTS === "ya" ? (
                    <input
                      type="number"
                      min="1"
                      name="ielts"
                      className="form-control mt-1"
                      placeholder="skor IELTS"
                      value={form.ielts}
                      onChange={handleChange}
                      onWheel={(e) => e.target.blur()}
                      onKeyPress={(e) => {
                        if (e.target.value.length >= 2) e.preventDefault();
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="row gap-3">
              <p className="fw-bold mt-5 mb-2">Riwayat Pendidikan</p>
              <div className="col card border-0 shadow p-3">
                <p className="fw-bold mb-2">
                  S1<span className="text-danger fs-5 fw-bold">*</span>
                </p>
                <div className="form-group card border-0 shadow w-100">
                  <label className="bg-blue p-input text-white rounded-top">
                    Universitas
                    <span className="text-danger fs-5 fw-bold">*</span>:
                  </label>
                  <div className="bg-blue2 p-input rounded-bottom">
                    <ReactSelect
                      className="basic-single mt-1"
                      classNamePrefix="select"
                      defaultValue={university}
                      name="university"
                      placeholder="Pilih atau ketik untuk mencari..."
                      options={dataUniversity}
                      filterOption={() => true}
                      onInputChange={(inputValue) => {
                        if (inputValue.length > 3)
                          return getListUniversity(inputValue);
                      }}
                      onChange={(selectedOption) =>
                        setUniversity(selectedOption)
                      }
                    />
                    {university.label === "Lainnya" ? (
                      <input
                        type="text"
                        name="university_str_s1"
                        className="form-control me-2 mt-2"
                        placeholder="universitas"
                        value={form.university_str_s1}
                        onChange={handleChange}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="form-group card border-0 shadow w-100 mt-3">
                  <label className="bg-blue p-input text-white rounded-top">
                    Fakultas<span className="text-danger fs-5 fw-bold">*</span>:
                  </label>
                  <div className="bg-blue2 p-input rounded-bottom">
                    <ReactSelect
                      className="basic-single mt-1"
                      classNamePrefix="select"
                      defaultValue={faculty}
                      name="faculty"
                      placeholder="Pilih atau ketik untuk mencari..."
                      options={dataFaculty}
                      filterOption={() => true}
                      onInputChange={(inputValue) => {
                        if (inputValue.length > 3)
                          return getListFaculty(inputValue);
                      }}
                      onChange={(selectedOption) => setFaculty(selectedOption)}
                    />
                    {faculty.label === "Lainnya" ? (
                      <input
                        type="text"
                        name="faculty_str_s1"
                        className="form-control me-2 mt-2"
                        placeholder="fakultas"
                        value={form.faculty_str_s1}
                        onChange={handleChange}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="form-group card border-0 shadow w-100 mt-3">
                  <label className="bg-blue p-input text-white rounded-top">
                    IPK<span className="text-danger fs-5 fw-bold">*</span>{" "}
                    (contoh: 3.25):
                  </label>
                  <div className="bg-blue2 p-input rounded-bottom">
                    <input
                      type="number"
                      name="ipk_s1"
                      className="form-control mt-1"
                      placeholder="ipk"
                      value={form.ipk_s1}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          [e.target.name]: e.target.value,
                        });
                        if (e.target.value > 4) {
                          setErrorIpk("Isi dengan IPK yang valid");
                        } else {
                          setErrorIpk("");
                        }
                      }}
                      onKeyPress={(e) => {
                        if (e.target.value.length > 3) e.preventDefault();
                      }}
                    />
                    <p className="text-danger mb-0">{errorIpk}</p>
                  </div>
                </div>
                <div className="form-group card border-0 shadow w-100 mt-3">
                  <label className="bg-blue p-input text-white rounded-top">
                    Tahun Lulus
                    <span className="text-danger fs-5 fw-bold">*</span>:
                  </label>
                  <div className="bg-blue2 p-input rounded-bottom">
                    <input
                      type="number"
                      min="1"
                      name="graduation_year_s1"
                      className="form-control mt-1"
                      placeholder="tahun lulus"
                      value={form.graduation_year_s1}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          [e.target.name]: e.target.value,
                        });
                        if (e.target.value.length < 4) {
                          setErrorGraduate("Isi dengan tahun yang valid");
                        } else {
                          setErrorGraduate("");
                        }
                      }}
                      onKeyPress={(e) => {
                        if (e.target.value.length >= 4) e.preventDefault();
                      }}
                    />
                    <p className="text-danger mb-0">{errorGraduate}</p>
                  </div>
                </div>
              </div>
              <div className="col card border-0 shadow p-3">
                <p className="fw-bold mb-2">S2 (opsional)</p>
                <div className="form-group card border-0 shadow w-100">
                  <label className="bg-blue p-input text-white rounded-top">
                    Universitas:
                  </label>
                  <div className="bg-blue2 p-input rounded-bottom">
                    <ReactSelect
                      className="basic-single mt-1"
                      classNamePrefix="select"
                      defaultValue={universityS2}
                      name="university"
                      placeholder="Pilih atau ketik untuk mencari..."
                      options={dataUniversityS2}
                      filterOption={() => true}
                      onInputChange={(inputValue) => {
                        if (inputValue.length > 3)
                          return getListUniversityS2(inputValue);
                      }}
                      onChange={(selectedOption) =>
                        setUniversityS2(selectedOption)
                      }
                    />
                    {universityS2.label === "Lainnya" ? (
                      <input
                        type="text"
                        name="university_str_s2"
                        className="form-control me-2 mt-2"
                        placeholder="universitas"
                        value={form.university_str_s2}
                        onChange={handleChange}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="form-group card border-0 shadow w-100 mt-3">
                  <label className="bg-blue p-input text-white rounded-top">
                    Fakultas:
                  </label>
                  <div className="bg-blue2 p-input rounded-bottom">
                    <ReactSelect
                      className="basic-single mt-1"
                      classNamePrefix="select"
                      defaultValue={facultyS2}
                      name="faculty"
                      placeholder="Pilih atau ketik untuk mencari..."
                      options={dataFacultyS2}
                      filterOption={() => true}
                      onInputChange={(inputValue) => {
                        if (inputValue.length > 3)
                          return getListFacultyS2(inputValue);
                      }}
                      onChange={(selectedOption) =>
                        setFacultyS2(selectedOption)
                      }
                    />
                    {facultyS2.label === "Lainnya" ? (
                      <input
                        type="text"
                        name="faculty_str_s2"
                        className="form-control me-2 mt-2"
                        placeholder="fakultas"
                        value={form.faculty_str_s2}
                        onChange={handleChange}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="form-group card border-0 shadow w-100 mt-3">
                  <label className="bg-blue p-input text-white rounded-top">
                    IPK (contoh: 3.25):
                  </label>
                  <div className="bg-blue2 p-input rounded-bottom">
                    <input
                      type="number"
                      name="ipk_s2"
                      className="form-control mt-1"
                      placeholder="ipk"
                      value={form.ipk_s2}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          [e.target.name]: e.target.value,
                        });
                        if (e.target.value > 4) {
                          setErrorIpkS2("Isi dengan IPK yang valid");
                        } else {
                          setErrorIpkS2("");
                        }
                      }}
                      onKeyPress={(e) => {
                        if (e.target.value.length > 3) e.preventDefault();
                      }}
                    />
                    <p className="text-danger mb-0">{errorIpkS2}</p>
                  </div>
                </div>
                <div className="form-group card border-0 shadow w-100 mt-3">
                  <label className="bg-blue p-input text-white rounded-top">
                    Tahun Lulus:
                  </label>
                  <div className="bg-blue2 p-input rounded-bottom">
                    <input
                      type="number"
                      min="1"
                      name="graduation_year_s2"
                      className="form-control mt-1"
                      placeholder="tahun lulus"
                      value={form.graduation_year_s2}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          [e.target.name]: e.target.value,
                        });
                        if (e.target.value.length < 4) {
                          setErrorGraduateS2("Isi dengan tahun yang valid");
                        } else {
                          setErrorGraduateS2("");
                        }
                      }}
                      onKeyPress={(e) => {
                        if (e.target.value.length >= 4) e.preventDefault();
                      }}
                    />
                    <p className="text-danger mb-0">{errorGraduateS2}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <p className="fw-bold">Unggah Dokumen</p>
              <div className="col">
                <div className="form-group card border-0 shadow w-100">
                  <label className="bg-blue p-input text-white rounded-top">
                    Pas Foto<span className="text-danger fs-5 fw-bold">*</span>{" "}
                    (4x6 cm):
                  </label>
                  <div className="bg-blue2 p-input rounded-bottom">
                    <input
                      className="form-control mt-1"
                      type="file"
                      id="file-input"
                      name="file-input"
                      accept=".jpg,.png, .pdf"
                      onChange={(e) => {
                        e.preventDefault();
                        setPhoto(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group card border-0 shadow w-100 mt-3">
                  <label className="bg-blue p-input text-white rounded-top">
                    KTP<span className="text-danger fs-5 fw-bold">*</span>:
                  </label>
                  <div className="bg-blue2 p-input rounded-bottom">
                    <input
                      className="form-control mt-1"
                      type="file"
                      accept=".jpg,.png, .pdf"
                      onChange={(e) => {
                        e.preventDefault();
                        setKtp(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group card border-0 shadow w-100 mt-3">
                  <label className="bg-blue p-input text-white rounded-top">
                    Surat lamaran harus di ttd peserta ODP di atas materai
                    10.000 yang ditujukan kepada Anggota Badan Pelaksana BPKH &
                    SDM, Pengadaan & Umum, Perencanaan & Pengkajian
                    <span className="text-danger fs-5 fw-bold">*</span>:
                  </label>
                  <div className="bg-blue2 p-input rounded-bottom">
                    <button
                      className="btn bg-blue text-white p-1 w-100"
                      onClick={() =>
                        window.open(
                          `/Surat Lamaran ODP BPKH 2023.docx`,
                          "Download"
                        )
                      }
                    >
                      Unduh Format Surat Lamaran BPKH
                    </button>
                    <input
                      className="form-control mt-2"
                      type="file"
                      accept=".jpg,.png, .pdf,.docx"
                      onChange={(e) => {
                        e.preventDefault();
                        setLamaran(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group card border-0 shadow w-100 mt-3">
                  <label className="bg-blue p-input text-white rounded-top">
                    Surat Pakta Integritas
                    <span className="text-danger fs-5 fw-bold">*</span>:
                  </label>
                  <div className="bg-blue2 p-input rounded-bottom">
                    <button
                      className="btn bg-blue text-white p-1 w-100"
                      onClick={() =>
                        window.open(`/Format Pakta Integritas.docx`, "Download")
                      }
                    >
                      Unduh Format Pakta Integritas
                    </button>
                    <input
                      className="form-control mt-2"
                      type="file"
                      accept=".jpg,.png, .pdf, .docx"
                      onChange={(e) => {
                        e.preventDefault();
                        setPakta(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group card border-0 shadow w-100 mt-3">
                  <label className="bg-blue p-input text-white rounded-top">
                    SKCK<span className="text-danger fs-5 fw-bold">*</span>{" "}
                    (Surat Keterangan Catatan Kepolisian):
                  </label>
                  <div className="bg-blue2 p-input rounded-bottom">
                    <input
                      className="form-control mt-1"
                      type="file"
                      accept=".jpg,.png, .pdf"
                      onChange={(e) => {
                        e.preventDefault();
                        setSkck(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group card border-0 shadow w-100 mt-3">
                  <label className="bg-blue p-input text-white rounded-top">
                    CV<span className="text-danger fs-5 fw-bold">*</span>:
                  </label>
                  <div className="bg-blue2 p-input rounded-bottom">
                    <input
                      className="form-control mt-1"
                      type="file"
                      accept=".jpg,.png, .pdf"
                      onChange={(e) => {
                        e.preventDefault();
                        setCv(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="form-group card border-0 shadow w-100">
                  <label className="bg-blue p-input text-white rounded-top">
                    Screenshot Bukti Kelulusan dari DIKTI di
                    <span className="text-danger fs-5 fw-bold">*</span>
                    <br />
                    <a
                      className="text-white"
                      target="_blank"
                      href="https://ijazah.kemdikbud.go.id/index.php/"
                    >
                      https://ijazah.kemdikbud.go.id/index.php/
                    </a>
                    <br />
                    (jika dari universitas luar negeri, maka bisa gunakan bukti
                    penyetaraan) :
                  </label>
                  <div className="bg-blue2 p-input rounded-bottom">
                    <input
                      className="form-control mt-1"
                      type="file"
                      accept=".jpg,.png, .pdf"
                      onChange={(e) => {
                        e.preventDefault();
                        setKelulusan(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group card border-0 shadow w-100 mt-3">
                  <label className="bg-blue p-input text-white rounded-top">
                    Sertifikat
                    <span className="text-danger fs-5 fw-bold">*</span> (Bisa
                    lebih dari 1 dokumen):
                  </label>
                  <div className="bg-blue2 p-input rounded-bottom">
                    {certificate.map((item, index) => (
                      <input
                        className="form-control mt-1"
                        type="file"
                        accept=".jpg,.png, .pdf"
                        onChange={(e) => {
                          e.preventDefault();
                          certificate[index] = e.target.files[0];
                          if (certificate[0].name !== undefined)
                            setCertificateFilled(true);
                        }}
                      />
                    ))}
                    <button
                      className="btn bg-blue text-white p-1 w-100 mt-2"
                      onClick={addCertificate}
                    >
                      Tambah Dokumen
                    </button>
                  </div>
                </div>
                <div className="form-group card border-0 shadow w-100 mt-3">
                  <label className="bg-blue p-input text-white rounded-top">
                    Ijazah<span className="text-danger fs-5 fw-bold">*</span>{" "}
                    (Bisa lebih dari 1 dokumen):
                  </label>
                  <div className="bg-blue2 p-input rounded-bottom">
                    {ijazah.map((item, index) => (
                      <input
                        className="form-control mt-1"
                        type="file"
                        accept=".jpg,.png, .pdf"
                        name="ijazah"
                        onChange={(e) => {
                          e.preventDefault();
                          ijazah[index] = e.target.files[0];
                          if (ijazah[0].name !== undefined)
                            setIjazahFilled(true);
                        }}
                      />
                    ))}
                    <button
                      className="btn bg-blue text-white p-1 w-100 mt-2"
                      onClick={addIjazah}
                    >
                      Tambah Dokumen
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="btn bg-blue w-25 text-white mt-4 mx-auto"
              onClick={() => setShow(true)}
              disabled={handleDisabled()}
            >
              Submit
            </button>
          </div> */}
        </div>
      {/* )} */}

      <Modal show={show}>
        <Modal.Body>
          <p className="fs-4 fw-bold mb-2 text-center">Konfirmasi</p>
          <p className="text-center">
            Apakah Anda yakin ingin mengunggah dokumen-dokumen ini?
          </p>
          <div className="d-flex justify-content-center">
            <div
              className="btn bg-blue mx-2 text-white px-4"
              onClick={handleSubmit}
            >
              {loadingButton === true ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Ya"
              )}
            </div>
            <div
              className="btn bg-blue mx-2 text-white px-4"
              onClick={() => setShow(false)}
            >
              Tidak
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showSuccess}>
        <Modal.Body>
          <p className="fs-4 fw-bold mb-2 text-center text-success">
            Terima Kasih
          </p>
          <p className="text-center">
            Data Anda telah berhasil terinput ke dalam sistem.
          </p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Form;
