import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import img from "../../assets/Asset 40@72x.png";
import logoAC from "../../assets/Logo Assesment Center-06.png";
import closedImg from "../../assets/closed.jpg";
import { Spinner } from "react-bootstrap";

const WelcomePage = () => {
  const navigate = useNavigate();
  const [dataCompany, setDataCompany] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id, id2 } = useParams();

  // useEffect(() => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_URL}ac/recruitment/participant/company_detail/${id2}`,
  //       {
  //         headers: {
  //           Authorization:
  //             "Basic " +
  //             btoa(
  //               `${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`
  //             ),
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       setDataCompany(res.data);
  //       setLoading(false);
  //     });
  // }, []);

  return (
    <section className="vh-100">
      <div className="container-fluid">
        {/* {loading === true ? (
          <div className="text-center p-5">
            <Spinner animation="border" />
          </div>
        ) : ( */}
          <div className="row">
            <img
              className="mx-auto m-3"
              style={{ width: "35%" }}
              alt=""
              src={closedImg}
            />
            {/* <div className="col card border-0">
              <div className="col-lg-10 my-auto mx-auto card border-0">
                <div className="d-flex mx-auto">
                  <img alt="" src={logoAC} width="auto" height={50} />
                  <div className="mx-3 fw-bold vr"></div>
                  <img
                    alt=""
                    src={dataCompany.image}
                    width="auto"
                    height={50}
                  />
                </div>
                <p className="text-center fs-4 fw-bold mt-3 text-blue text-uppercase">
                  Form Aplikasi Recruitment ODP Program BPKH 2023
                </p>
                <p className="text-center fw-bold mb-1">
                  Selamat datang peserta calon kandidat Program ODP BPKH 2023
                </p>
                <p className="text-center text-danger d-block d-sm-none mt-2">Peserta diharapkan untuk akses via web-browser (best view) laptop</p>
                <button
                  className="btn bg-blue p-1 w-100 mt-3 text-white d-none d-sm-block"
                  onClick={() => navigate(`/form/${id}/${id2}`)}
                  type="submit"
                >
                  Mulai
                </button>
                <p className="text-center mt-4 text-xs">
                  Copyright © Assessment Center Solutions 2023.
                  <br />
                  All rights reserved. v 1.0.0
                </p>
              </div>
            </div>

            <div className="col px-0 d-none d-sm-block">
              <img
                alt=""
                src={img}
                className="w-100 vh-100"
                style={{ objectFit: "cover", objectPosition: "left" }}
              />
            </div> */}
          </div>
        {/* )} */}
      </div>
    </section>
  );
};

export default WelcomePage;
